<template>
	<div class="statistics-container">
		<v-title>
			<Breadcrumb>
				<Breadcrumb-item><i class="icon-home"></i>数据报告</Breadcrumb-item>
			</Breadcrumb>
		</v-title>
		<section class="content-panel">
			<ul class="tabbar">
				<li :class="['tabbar-item', selectedTab === tab.name ? 'active': '', tab.tab.length > 10 ? 'text-12px' : '']" 
            v-for="tab in tabs"
            :key="tab.name"
            @click="handleClickTab(tab.name)"
        >{{tab.tab}}</li>
			</ul>
			<router-view class="content"></router-view>
		</section>
	</div>
</template>

<script type="text/ecmascript-6">
import VTitle from 'components/topHeader/topHeader';

export default {
	components: { VTitle },
	data() {
		return {
			tabs: [
				{ tab: '生理对比报告', name: 'reportPhysiology' },
				{ tab: '顾客月度报表', name: 'reportCustomer' },
				{ tab: '心电评估报告', name: 'reportHeart' },
				{ tab: '训练报表记录', name: 'reportTrain' },
				{ tab: '心肺耐力测试', name: 'reportPatient' },
				{ tab: '量化差值管理', name: 'reportQuantify' },
				{ tab: '13+1血压及血管风险管理', name: 'reportBloodPressure' },
				{ tab: '5点血糖管理', name: 'reportBloodSugar' },
				{ tab: 'SUN风险评估方案', name: 'reportSun' },
			],
		};
	},
	computed: {
		selectedTab() {
			return this.$route.name;
		},
	},
	methods: {
		handleClickTab(tab) {
			this.$router.push({ name: tab });
		},
	},
};
</script>

<style lang="less" scoped>
	.content-panel {
		display: flex;
		justify-content: center;

		.tabbar {
			padding: 22px 15px;

			&-item {
				height: 32px;
				line-height: 32px;
				width: 140px;
				text-align: center;
				background-color: #fff;
				margin-bottom: 20px;
				border-radius: 4px;
				box-shadow: 0 2px 5px #bbb;
				cursor: pointer;

				&.text-12px {
					font-size: 12px;
				}

				&.active {
					background-color: #fdad00;
					color: #fff;
					font-weight: 700;
				}
			}
		}

		.content {
			width: 1130px;
		}
	}
</style>
